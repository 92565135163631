.banner {

  .fullWidthFluid{
    background-color: #000;
    padding:30px 80px;

    @media(max-width:1185px){
      padding:30px;
    }
    .colchange, .colchange2{
      @media(max-width:1085px){
        width:100%;
      }

      .navigation-primary{
        @media(max-width:1085px){
          text-align:center;
        }
      }

      .nav-right{
        float:none;
        text-align:center;
      }

    }

  }

  .navigation-primary {
    ul {
      li {
        display: inline;
        text-transform: uppercase;

        @media(max-width:1085px){
          font-size: 12px;
        }

        &:after{
          content:"|";
          color:#31a9c1;
          margin:0 10px;
          text-decoration:none !important;

          @media(max-width:1085px){
            margin:0 5px;
          }
        }

        a {
          color: white;
          padding:10px 0;
          display: inline-block;
          transition:0.2s;
          &:hover, &:focus, &:active{
            background-color:#000;
            color:#ee6634;
            text-decoration:none;
            transition:0.2s;
          }
        }

        &:last-child{
          &:after{
            content:"";
            margin:0;
          }
        }
      }
    }
    .active a{
      color:#ee6634;
    }
  }

  .nav-right{
    float:right;

    @media(max-width:992px){
      clear:right;
      text-align:center;
    }

    .social-icons{
      display:inline-block;
      margin-right:10px;

      @media(max-width:1085px){
        margin-right:5px;
      }

      .fa{
        color:#000;
        background:$brand-primary;
        border:1px solid $brand-primary;
        min-width:14px;
        text-align:center;
        border-radius:50%;
        width:30px;
        height:30px;
        line-height:30px;
        margin-right:5px;
        transition:0.2s;
        &:hover{
          background:#000;
          color: $brand-primary;
          transition:0.2s;
        }
      }
    }
    .donate-button{
      display:inline-block;
    }
  }

  .navigation-mobile{

    .fa-bars{
      color:#FFF;
      position: relative;
      right: -35px;
    }

    .mobile-menu-items{
        display:none;

      .nav-right .social-icons{
        margin-right:0;
      }
    }

    .nav-mobile{

      padding-left:0;

      li{

        list-style-type:none;
        text-transform:uppercase;
        text-align:center;
        margin-bottom:20px;

        a{

        }
      }

    }
  }

}

.mobile-menu-items{
  margin-top: 30px;
  a{
    color: #fff;
  }
  .active{
    a{
      color:#ee6634;
      text-decoration: underline;
    }
  }
}

#mob-logo{
  width: 160px;
  position: absolute;
  top: -15px;
  left: -20px;
  img{
    width:100%;
  }
}

.mob-social-icons{
  position: absolute;
  right: 15px;
  font-size: 30px;
  top: -7px;
  @media(max-width:360px){
    font-size: 25px;
    top: -4px;
  }
  a{
    margin-right:10px;
    @media(max-width:360px) {
      margin-right: 5px;
    }
  }
}