.footer-top {

  border-top:10px solid #6dc0d0;
  background-repeat: no-repeat;
  background-size: cover;

  .donate-box {

    .donate-button-holder {
      padding: 20px 0;
      width: 60%;
      margin: 0 auto;
      font-size: 22px;

      @media(max-width:1200px){
        width: 70%;
      }

      a {
        display: block;
        text-align: center
      }

    }

    margin-top: 70px;
    margin-bottom: 70px;
    background-color: #6dc0d0;
    border: 3px solid white;
    @media(max-width:600px){
      width: 100%;
      margin: 30px 0px;
    }
    img {
      display: block;
      max-width: 80%;
      margin: 30px auto;
    }
  }
}

.footer-custom{
  padding:50px 0;
  background-color:#505050;
  border-top:10px solid #6dc0d0;

  ul{
    margin-bottom:0;
    padding-left:0;

    @media(max-width:992px){
      margin-bottom:30px;
    }

    li{
      list-style-type:none;
      font-size:20px;
      margin-bottom:5px;

      @media(max-width:992px){
        text-align:center;
      }

      a{
        color:white;
        transition:0.2s;
        &:hover{
          transition:0.2s;
          text-decoration: none;
          color: #ee6634;
        }
      }
    }
  }

  .footer-social{
    text-align:right;
    .fa{
      color:#505050;
      background:$brand-primary;
      border:1px solid #ee6634;
      min-width:14px;
      text-align:center;
      border-radius:50%;
      width:30px;
      height:30px;
      line-height:30px;
      margin-right:20px;
      transition: 0.2s;

      &:hover{
        background: none;
        color:#ee6634;
        transition: 0.2s;
      }

      &:last-child {
        margin-right: 0;
      }
    }
    p{
      font-size:20px;
      color:white;
    }

    @media(max-width:992px){
      text-align:center;
      margin-bottom:20px;
    }
  }

  .logo-holder img{
    max-width: 80%;
    margin: 0 auto;
    display: block;
    margin-bottom:40px;

    @media(max-width:992px){
      max-width: 40%;
    }

  }

  .tandc{
    text-align:center;
    color:white;

    .company-section{
      margin-right:10px;
    }

    a{
      color:white;
      padding:0 10px;
      transition:0.2s;
      @media(max-width:450px){
        display: block;
      }

      &:hover{
        transition:0.2s;
        color: #ee6634;
        text-decoration: none;
      }
      
    }

    #charity{
      padding-left:0px !important;
    }
    @media(max-width:450px){
      .divide{
        display: none;
      }

    }
  }

}