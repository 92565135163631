.btn-custom{
  background-color:#21d3f7;
  color:white;
  border:1px solid #21d3f7;
  padding:10px 20px;
  text-transform:uppercase;
  transition:0.3s;

  &:hover, &:focus, &:active{
    opacity:0.7;
    text-decoration:none;
    color:white;
  }

  @media(max-width:1085px){
    padding:12px 10px;
  }

}

.btn-brand{
    @extend .btn-custom;
    border: none;
    @media(max-width:768px){
      width:100%;
      display: block;
      text-align:center;
    }
}

.btn-custom-black{
  background-color:#000;
  color:white;
  border:1px solid #000;
  padding:10px 20px;
  text-transform:uppercase;
  transition:0.3s;

  &:hover, &:focus, &:active{
    opacity:0.7;
    text-decoration:none;
    color:white;
  }

}