body {
    font-family: $primary-font;
}

/* Homepage Styles */
.custom-home {
    /* Hero section moved to global*/
    .hero-section {
        padding: 80px 0 80px 0;
        max-height:600px;
        background-size:cover;
        overflow: hidden;
        @media (max-width: 768px) {
            height: auto;
        }

        .hero-button-holder {
            margin-top: 50px;
        }

        h1 {
            font-size:30px;
            margin:20px auto 10px auto;
        }

    }

    .custom-home-section {
        a {
            &.btn-brand {
                margin-top: 40px;
            }
            @media(max-width:768px){
                display: block;
                text-align: center;
            }
        }
    }

    overflow: auto;
}

.content-blocks {
    .image-content {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        // min-height: 563px;
        @media(max-width:992px){
            height:400px;
        }
    }

    .text-content {
        padding: 80px;

        font-size: 18px;
        color: white;
        display: inline-block;

        @media(max-width:992px){
            float:none;
            text-align: center;
            width:100%;
        }

        @media(max-width:768px){
            padding:40px;
            text-align: left;
        }

        h2 {
            font-weight: 700;
            margin-bottom: 20px;
            margin-top: 0;
        }

        p {
            margin-bottom: 20px;

            i {
                margin: 30px 0;
            }
        }

        ul {
            padding-left: 30px;

            li {
                margin-bottom: 20px;
                list-style-type: none;

                &:before {
                    font-family: 'FontAwesome';
                    content: '\f061';
                    margin: 0 13px 0 -30px;
                    color: #FFF;
                }
            }
        }
    }
}

.text-content.text-only-centered {
    text-align: center;
    min-height: 0;
}

/* Standard Template Styles */
.custom-standard {
    .hero-section {
        padding: 50px 0;
        height:600px;
        @media (max-width: 768px) {
            height:auto;
        }

        //h1 {
        //    margin-top: 0;
        //}
    }
}

/* Blog Archive/Index Styles */

.blog-archive-custom {
    margin-bottom: 40px;

    .blog-link {
        transition:0.2s;
        background-repeat:no-repeat;
        background-position: 50%;
        background-size:cover;
        .img {
            height: 330px;
            transition: filter .5s ease-in-out;
            -webkit-filter: grayscale(0%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
            filter: grayscale(0%); /* FF 35+ */
        }
        &:hover{
            transition:0.2s;
            text-decoration:none;
            -webkit-filter: grayscale(100%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
            filter: grayscale(100%); /* FF 35+ */
        }
        h3{
            margin-bottom:5px;
        }
        p{
            color:#000;
        }
    }
}

/* Contact Template Styles */

.custom-contact {

    .featured-img{
        max-height:200px;
    }

    #map {
        height: 100vh;

        @media (max-width: 992px) {
            height: 50vh;
        }
    }

    .content-side {
        padding: 20px 95px;

        img{
            max-width:100%;
        }

        @media (max-width: 600px) {
            padding: 15px 40px;
        }

        label {
            width: 100%;
        }

        input, textarea {
            width: 100%;
            box-shadow: 0;
            border: 1px solid #000;
            max-height:80px;
            padding:8px;
            &:focus{
                border: 1px solid #ee6634;
                outline: none;
            }
        }

        .wpcf7-submit {
            padding: 20px;
            text-transform: uppercase;
            transition: 0.3s;
            margin-top:15px;

            &:hover {
                background-color: $brand-primary;
                color: white;
            }
        }

        div.wpcf7-response-output {
            margin: 0;
            text-align: center;
        }
    }
}

.single-custom{
    .entry-content{
        margin:30px 0;
        p {
            color: #000;
            font-size: 16px;
        }
    }

    .entry-title{
        margin:30px 0;
    }

    img{
        max-width:100%;
    }
    .single-img{
        width:100%;
    }
}

.policies{
    margin-bottom: 30px;
    h1{
        margin-bottom:20px;
    }
    p{
        line-height:1.5;
    }
}

.error404{
    form{
        margin:15px 0 30px 0;
    }

    label{
        @media(max-width:600px){
            display:block;
        }
    }

    input{
        @media(max-width:600px){
            width:100%;
        }
    }

    .search-submit{
        background-color: #21d3f7;
        color: #fff;
        border: 1px solid #21d3f7;
        padding: 6px 20px;
        text-transform: uppercase;
        transition: .3s;
        position: relative;
        vertical-align:top;
        @media(max-width:600px){
            width:100%;
        }
        &:hover{
            background:#fff;
            color:#21d3f7;
        }
    }
}

.search-container{
    margin-bottom:30px;
}

.mailtolink{
    display:block;
    text-align: center;
    transition:0.2s;
    text-decoration: none;
    &:hover{
        transition:0.2s;
        opacity:1 !important;
    }
}


@media (min-width: 992px) {
    .max-content {
        max-width: 50%;
        margin: 0 auto;
    }
}