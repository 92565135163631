// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:#ee6634;

// Fonts
$primary-font: "Open Sans", sans-serif;
