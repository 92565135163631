.hero-section {
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    overflow: hidden;

    .hero-section__logo {
        max-height: 200px;
    }

    p {
        font-size: 16px;

        i {
            font-weight: 500;
        }
    }
}